var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"longPage beijingCityRepresentativeAncientTree_Index_mobile flex flex-cl"},[_c('NavigatorBar'),_vm._m(0),_c('div',{staticClass:"container m-h-20"},[_c('YinghaoTitle',{attrs:{"title":"北京古树之王的故事"}}),_c('p',{staticClass:"m-t-15 m-b-30"},[_vm._v("北京城市代表性古树鉴赏")]),_vm._l((_vm.trees),function(tree,index){return _c('div',{key:tree.title,staticClass:"item m-h-10 m-b-10"},[_c('img',{staticClass:"imgBg",attrs:{"src":tree.img,"alt":""}}),_c('div',{staticClass:"img-float-bg"}),_c('div',{staticClass:"leftTop flex-ct"},[_vm._v("古树之王")]),_c('div',{staticClass:"leftBottom"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(tree.title))]),_c('div',{staticClass:"subTitle m-t-10"},[_vm._v(_vm._s(tree.subTitle))])]),(index < 6)?_c('router-link',{staticClass:"btn rightBottom flex-ct",attrs:{"to":`beijing-city-representative-ancient-tree-detail?id=${tree.title}`}},[_vm._v("立即探索")]):_c('div',{directives:[{name:"download",rawName:"v-download",value:({
          url: _vm.downloadFile.path,
          fileName: _vm.downloadFile.fileName
        }),expression:"{\n          url: downloadFile.path,\n          fileName: downloadFile.fileName\n        }"}],staticClass:"btn disabled rightBottom flex-ct"},[_vm._v(" 下载手册了解更多 ")])],1)})],2),_c('div',{directives:[{name:"download",rawName:"v-download",value:({
      url: _vm.downloadFile.path,
      fileName: _vm.downloadFile.fileName
    }),expression:"{\n      url: downloadFile.path,\n      fileName: downloadFile.fileName\n    }"}],staticClass:"flex-ct m-t-25"},[_c('span',{staticClass:"btnDownload p-h-12 flex-ct"},[_vm._v("点击下载指南手册")])]),_c('div',{staticClass:"p-b-20"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/images/logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }