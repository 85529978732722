var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-cl beijingCityRepresentativeAncientTree_pc_page"},[_vm._m(0),_c('div',{staticClass:"flex-1 swiperOuter"},[_c('div',{ref:"swiper",staticClass:"swiper swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.trees),function(tree,index){return _c('div',{key:tree.title,staticClass:"swiper-slide"},[_c('Page',{attrs:{"title":tree.title,"subtitle":tree.subTitle,"routePath":tree.detail
                ? `/beijing-city-representative-ancient-tree-detail?id=${tree.title}`
                : '',"mainImg":tree.img}})],1)}),0)]),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-button-next"})]),_c('div',{directives:[{name:"download",rawName:"v-download",value:({
      url: _vm.downloadFile.path,
      fileName: _vm.downloadFile.fileName
    }),expression:"{\n      url: downloadFile.path,\n      fileName: downloadFile.fileName\n    }"}],staticClass:"flex-ct footerOuter"},[_c('span',{staticClass:"btnDownload p-h-12 flex-ct"},[_vm._v("下载指南手册了解更多")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-ct"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/images/pc/奥迪logo.png"),"alt":""}})])
}]

export { render, staticRenderFns }