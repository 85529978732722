<template>
  <div style="height: 100%">
    <IndexM v-if="isMobile" />
    <IndexP v-else />
  </div>
</template>

<script>
import IndexM from "./detail_m.vue";
import IndexP from "./detail_p.vue";
import { mapState } from "vuex";
export default {
  components: {
    IndexM,
    IndexP
  },
  computed: {
    ...mapState(["isMobile"])
  }
};
</script>
