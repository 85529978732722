<template>
  <span class="title p-h-5">
    {{ title }}
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 26px;
  background-image: url("~@/images/page-top-yinhao1.png"),
    url("~@/images/page-top-yinhao2.png");
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  background-size: 7px auto, 7px auto;
}
</style>
