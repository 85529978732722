import { mapState } from "vuex";
import YinghaoTitle from "@/components/YingHaoTitle.vue";
export default {
  components: {
    YinghaoTitle
  },
  computed: {
    ...mapState(["trees", "downloadFile"])
  }
};
