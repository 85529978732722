var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"flex-1 mainArea",staticStyle:{"flex-grow":"10"}},[_c('div',{staticClass:"container flex-1"},[_c('div',{staticClass:"top"},[(_vm.title)?_c('div',{class:[' flex flex-ct m-t-15']},[_c('div',{staticClass:"title p-h-8"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),(_vm.subtitle)?_c('div',{class:['homePagesubtitle m-t-8']},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]),_c('div',{class:['imgArea m-t-8']},[(_vm.routePath)?_c('router-link',{attrs:{"to":_vm.routePath}},[_c('img',{staticClass:"img_w_100 img_h_100 img_cover mainImg",attrs:{"src":_vm.mainImg,"alt":""}})]):_c('img',{directives:[{name:"download",rawName:"v-download",value:({
            url: _vm.downloadFile.path,
            fileName: _vm.downloadFile.fileName
          }),expression:"{\n            url: downloadFile.path,\n            fileName: downloadFile.fileName\n          }"}],staticClass:"img_w_100 img_h_100 img_cover mainImg",attrs:{"src":_vm.mainImg,"alt":""}})],1),(_vm.routePath)?[_c('router-link',{attrs:{"to":_vm.routePath}},[_c('div',{staticClass:"more flex flex-ct"},[_c('img',{staticClass:"img",attrs:{"src":require("@/images/more_arrow_right.png"),"alt":""}}),_c('div',{staticClass:"p-h-15"},[_vm._v("点击探索更多")]),_c('img',{staticClass:"img",attrs:{"src":require("@/images/more_arrow_left.png"),"alt":""}})])])]:[_c('div',{directives:[{name:"download",rawName:"v-download",value:({
            url: _vm.downloadFile.path,
            fileName: _vm.downloadFile.fileName
          }),expression:"{\n            url: downloadFile.path,\n            fileName: downloadFile.fileName\n          }"}],staticClass:"more flex flex-ct"},[_c('img',{staticClass:"img",attrs:{"src":require("@/images/more_arrow_right.png"),"alt":""}}),_c('div',{staticClass:"p-h-15"},[_vm._v("下载手册了解更多")]),_c('img',{staticClass:"img",attrs:{"src":require("@/images/more_arrow_left.png"),"alt":""}})])]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }