<template>
  <div
    class="beijingCityRepresentativeAncientTree_detail_page_pc"
    :style="{ backgroundImage: `url(${pageBgImg})` }"
  >
    <div style="flex: 75"></div>
    <div class="flex flex-ct">
      <img class="logo" src="~@/images/pc/奥迪logo.png" alt="" />
    </div>
    <div style="flex: 70"></div>
    <div class="flex flex-cl middelAreaContainer">
      <div class="flex middelArea">
        <div @click="clickPrev" class="btnArrow btnArrow-left flex flex-ct">
          <div class="swiper-button-prev" ref="swiperButtonPrev"></div>
        </div>
        <div class="container">
          <div class="detailContainer">
            <DetailPure :detail="currentTree" />
            <!-- <van-swipe
              @change="onChange"
              class="my-swipe"
              :autoplay="6000"
              :show-indicators="false"
              ref="myVantSwipe"
            >
              <van-swipe-item v-for="tree in hasDetailTrees" :key="tree.title">
                <div class="scrollContainer"><DetailPure :detail="tree" /></div>
              </van-swipe-item>
            </van-swipe> -->
          </div>
        </div>
        <div @click="clickNext" class="btnArrow btnArrow-right flex-ct">
          <div class="swiper-button-next" ref="swiperButtonNext"></div>
        </div>
      </div>
    </div>
    <div style="flex: 41"></div>

    <div class="flex flex-ct bottom-btns">
      <div
        class="btn flex flex-ct m-r-10"
        @click="gotoPage('/beijing-ancient-tree-resources')"
      >
        北京市古树资源情况
      </div>
      <div
        class="btn flex flex-ct m-l-10"
        @click="gotoPage('/beijing-ancient-tree-map')"
      >
        北京市古树观赏地图
      </div>
    </div>

    <div style="flex: 45"></div>
    <div class="flex flex-ct footer">
      ·奥迪中国与北京绿化基金会联合呈现·<br />支持单位：
      国家林业草原古树健康与古树文化工程技术研究中心
    </div>

    <div style="flex: 50"></div>
  </div>
</template>

<script>
import myMixinDetail from "./detail.js";
import DetailPure from "./detailPure_p.vue";

import "swiper/swiper-bundle.css";
export default {
  components: {
    DetailPure
  },
  mixins: [myMixinDetail],

  data() {
    return {
      current: 0,
      pageBgImg: require("@/images/pc/北京城市代表性古树-PC三级页面｜内容详情.jpg")
    };
  },
  computed: {
    index() {
      const index = this.trees.findIndex(
        (tree) => tree.title === this.$route.query.id
      );
      console.log("index", index);
      return index;
    },
    hasDetailTrees() {
      return this.trees.filter((item) => item.detail);
    },
    currentTree() {
      return this.hasDetailTrees[this.index];
    },
    nextTree() {
      const nextIndex = this.index + 1;
      if (nextIndex >= this.hasDetailTrees.length) {
        return this.hasDetailTrees[0];
      }
      return this.hasDetailTrees[nextIndex];
    },
    prevTree() {
      const prevIndex = this.index - 1;
      if (prevIndex < 0) {
        return this.hasDetailTrees[this.hasDetailTrees.length - 1];
      }
      return this.hasDetailTrees[prevIndex];
    }
  },
  mounted() {
    if (this.$refs.myVantSwipe) {
      this.$refs.myVantSwipe.swipeTo(this.index, {
        immediate: true
      });
    }
  },
  activated: function () {
    this.$refs.myVantSwipe &&
      this.$refs.myVantSwipe.swipeTo(this.index, {
        immediate: true
      });
  },
  methods: {
    onChange(index) {
      this.current = index;
      const title = this.$route.query.id;
      if (title !== this.hasDetailTrees[index].title) {
        this.$router.replace({
          path: "/beijing-city-representative-ancient-tree-detail",
          query: {
            id: this.hasDetailTrees[index].title
          }
        });
      }
    },
    gotoPage(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    replacePage(path) {
      if (path) {
        this.$router.replace(path);
      }
    },
    clickPrev() {
      console.log(this.prevTree);
      this.$router.replace({
        path: "/beijing-city-representative-ancient-tree-detail",
        query: {
          id: this.prevTree.title
        }
      });
    },
    clickNext() {
      console.log(this.nextTree);
      this.$router.replace({
        path: "/beijing-city-representative-ancient-tree-detail",
        query: {
          id: this.nextTree.title
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.beijingCityRepresentativeAncientTree_detail_page_pc {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  .logo {
    height: 94px;
  }
  .middelAreaContainer {
    width: 100%;
    height: 57vh;
  }
  .middelArea {
    height: 100%;
    overflow: hidden;
    flex: 1;
    .btnArrow {
      flex: 1;
    }
    .btnArrow-left {
      left: 20px;
    }
    .btnArrow-right {
      right: 20px;
    }
    ::v-deep {
      .swiper-button-prev,
      .swiper-button-next {
        width: 40px;
        height: 80px;
        left: auto;
        right: auto;
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 40px;
        color: #fff;
      }
    }
  }
  .container {
    width: 80%;
    max-width: 953px;
    margin: 0 auto;
    border-radius: 12px;
    background: rgba($color: #000000, $alpha: 0.4);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .detailContainer {
    flex-grow: 0;
    overflow-y: auto;
    padding-bottom: 10px;
    background: #fff;
    border-radius: 12px;
  }
  .bottom-btns {
    .btn {
      width: 296px;
      height: 68px;
      font-size: 19px;

      background: #0000007a;
      border-radius: 40px;
      border: 1px solid #ffffff61;
      cursor: pointer;
    }
  }
  .footer {
    font-size: 20px;
  }
}
</style>
