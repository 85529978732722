import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["isMobile", "trees"]),
    detail() {
      const index = this.trees.findIndex(
        (tree) => tree.title === this.$route.query.id
      );
      if (index > -1) {
        return this.trees[index];
      }
      return null;
    }
  },
  mounted() {}
};
