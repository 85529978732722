import { render, staticRenderFns } from "./YingHaoTitle.vue?vue&type=template&id=755ae733&scoped=true"
import script from "./YingHaoTitle.vue?vue&type=script&lang=js"
export * from "./YingHaoTitle.vue?vue&type=script&lang=js"
import style0 from "./YingHaoTitle.vue?vue&type=style&index=0&id=755ae733&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755ae733",
  null
  
)

export default component.exports