<template>
  <div class="viewMethodsPage detailPure_p" v-if="detail">
    <div class="topImg">
      <img class="img_w_100 img" :src="detail.img" alt="" />
      <div class="bl"></div>
      <div class="imgAuthor flex flex-ct">{{ detail.imgAuthor }}</div>
    </div>
    <div class="p-h-24 contentArea">
      <div class="flex top">
        <YingHaoBlackTitle :title="detail.title"></YingHaoBlackTitle>
      </div>
      <div class="flex m-t-10 m-b-22">
        <div class="green-underline detailPagesubTitle">
          {{ detail.subTitle }}
        </div>
        <div class="flex-1"></div>
      </div>
      <div class="content" v-if="detail.detail">
        <div v-for="(detailItem, index) in detail.detail" :key="index">
          <Button1
            :title="detailItem.title"
            class="m-t-18"
            v-if="detailItem.title"
          ></Button1>
          <div
            class="flex treeItem"
            v-for="item in detailItem.items"
            :key="item"
          >
            <van-icon name="arrow" class="arrow" />
            <p v-html="item"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myMixinDetailPage } from "@/mixins/myMixinDetailPage";
import mixin from "./index.js";

export default {
  props: {
    detail: {
      type: Object
    }
  },
  mixins: [myMixinDetailPage, mixin]
};
</script>

<style lang="scss" scoped>
.detailPure_p {
  .topImg {
    .img {
      max-height: 30vh;
      object-fit: cover;
    }
  }
  .imgAuthor {
    position: absolute;
    bottom: 0;
    font-size: 20px;
    color: #777777;
    height: 60px;
    text-align: right;
    right: 26px;
    z-index: 10;
  }
  .detailPagesubTitle {
    font-size: 30px;
  }
  .treeItem {
    .arrow {
      margin-top: 12px;
    }
  }
}
</style>
