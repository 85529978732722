<template>
  <div class="flex-cl beijingCityRepresentativeAncientTree_pc_page">
    <div class="flex flex-ct">
      <img class="logo" src="~@/images/pc/奥迪logo.png" alt="" />
    </div>
    <div class="flex-1 swiperOuter">
      <div class="swiper swiper-container" ref="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(tree, index) in trees"
            :key="tree.title"
          >
            <Page
              :title="tree.title"
              :subtitle="tree.subTitle"
              :routePath="
                tree.detail
                  ? `/beijing-city-representative-ancient-tree-detail?id=${tree.title}`
                  : ''
              "
              :mainImg="tree.img"
            >
            </Page>
          </div>
        </div>
      </div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div
      class="flex-ct footerOuter"
      v-download="{
        url: downloadFile.path,
        fileName: downloadFile.fileName
      }"
    >
      <span class="btnDownload p-h-12 flex-ct">下载指南手册了解更多</span>
    </div>
  </div>
</template>

<script>
import mixin from "./index.js";
// 引入 Swiper 和它的样式文件
import Swiper, { EffectCoverflow, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import Page from "./Page_p.vue";
// 由于 Swiper 6 对 ES 模块支持不够好，EffectCoverflow 可能需要以这种方式单独引入
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
Swiper.use([EffectCoverflow, Navigation]);
export default {
  mixins: [mixin],
  components: {
    Page
  },
  data() {
    return {
      swiper: null
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      // direction: "vertical",
      slidesPerView: "4",
      // loop: true,
      // centeredSlides: true,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      on: {
        click: (e) => {
          console.log(e);
        }
      }
    });
  },
  methods: {},
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.beijingCityRepresentativeAncientTree_pc_page {
  height: 100%;

  background-color: #2c2b2b;
  background-image: url("~@/images/pc/北京城市代表古树PC二级页面.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  .logo {
    margin: 75px auto 100px;
    height: 94px;
    width: auto;
  }
  .swiperOuter {
    margin: 0 100px;
    ::v-deep {
      .swiper-button-prev,
      .swiper-button-next {
        width: 40px;
        height: 80px;
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 40px;
        color: #fff;
      }
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footerOuter {
    padding: 71px 0 41px;
  }
  .btnDownload {
    height: 31px;
    background: #40bf65;
    font-size: 20px;
    color: #ffffff;
    border-radius: 31px;
    cursor: pointer;
  }
}
</style>
