<template>
  <div
    class="longPage beijingCityRepresentativeAncientTree_Index_mobile flex flex-cl"
  >
    <NavigatorBar />
    <div>
      <img class="logo" src="@/images/logo.png" alt="" />
    </div>
    <div class="container m-h-20">
      <YinghaoTitle title="北京古树之王的故事" />
      <p class="m-t-15 m-b-30">北京城市代表性古树鉴赏</p>
      <div
        v-for="(tree, index) in trees"
        :key="tree.title"
        class="item m-h-10 m-b-10"
      >
        <img class="imgBg" :src="tree.img" alt="" />
        <div class="img-float-bg"></div>
        <div class="leftTop flex-ct">古树之王</div>
        <div class="leftBottom">
          <div class="title">{{ tree.title }}</div>
          <div class="subTitle m-t-10">{{ tree.subTitle }}</div>
        </div>

        <router-link
          v-if="index < 6"
          class="btn rightBottom flex-ct"
          :to="`beijing-city-representative-ancient-tree-detail?id=${tree.title}`"
          >立即探索</router-link
        >
        <div
          v-else
          class="btn disabled rightBottom flex-ct"
          v-download="{
            url: downloadFile.path,
            fileName: downloadFile.fileName
          }"
        >
          下载手册了解更多
        </div>
      </div>
    </div>

    <div
      class="flex-ct m-t-25"
      v-download="{
        url: downloadFile.path,
        fileName: downloadFile.fileName
      }"
    >
      <span class="btnDownload p-h-12 flex-ct">点击下载指南手册</span>
    </div>
    <div class="p-b-20"></div>
  </div>
</template>

<script>
import mixin from "./index.js";
export default {
  mixins: [mixin]
};
</script>

<style lang="scss" scoped>
.beijingCityRepresentativeAncientTree_Index_mobile {
}
.longPage {
  color: #fff;
  background: url("~@/images/bg-representative.jpg") repeat-y;
  background-size: cover;
  height: 100%;
}
.logo {
  width: 137px;
  margin: 40px auto 40px;
}
.container {
  flex-grow: 1;
  padding-top: 32px;
  border-radius: 12px;
  background-color: rgba($color: #000000, $alpha: 0.4);
  border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
  overflow-y: auto;

  .item {
    height: 158px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    .imgBg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img-float-bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      // background: rgba(0, 0, 0, 0.2);
    }
    .leftTop {
      position: absolute;
      top: 10px;
      left: 9px;
      text-align: left;
      background-image: url("~@/images/tag_bg_gszw.png");
      background-size: 100% 100%;
      width: 60px;
      height: 18px;
      font-size: 8px;
    }
    .leftBottom {
      position: absolute;
      bottom: 10px;
      left: 9px;
      text-align: left;
      background: rgba(0, 0, 0, 0.39);
      box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.39);
      .title {
        font-size: 14px;
      }
      .subTitle {
        font-size: 9px;
      }
    }
    .rightBottom {
      height: 20px;
      position: absolute;
      right: 8px;
      bottom: 8px;
      padding: 0 10px;
      background: #40bf65;
      font-size: 10px;
      color: #ffffff;
      border-radius: 20px;
      &.disabled {
        background: #989998;
      }
    }
  }
}
.btnDownload {
  height: 20px;
  background: #40bf65;
  font-size: 10px;
  color: #ffffff;
  border-radius: 20px;
}
</style>
